import React from 'react'
import { Head } from '~/components'
import SearchResults from '~/components/SearchResults'
import Footer from '~/components/Footer'
import Breadcrumb from '~/components/Breadcrumb'
import { Router } from '@reach/router'

function Search(props) {
  const q = props.query

  return (
    <>
      <Head />
      <Breadcrumb />
      <SearchResults q={q} />
      <Footer />
    </>
  )
}

export default function ResultadosBusca(props) {
  return (
    <Router>
      <Search {...props} path="/resultados-busca/:query" />
    </Router>
  )
}
