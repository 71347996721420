// extracted by mini-css-extract-plugin
export var bottomFooter = "Footer-module--bottom-footer--t+qil";
export var footerContainer = "Footer-module--footer-container--cT4dY";
export var iconAirplane = "Footer-module--icon-airplane--rtKs3";
export var iconBag = "Footer-module--icon-bag--bsA8l";
export var iconBus = "Footer-module--icon-bus--NFFoF";
export var iconCar = "Footer-module--icon-car--EO7Tu";
export var iconCard = "Footer-module--icon-card--XlXtu";
export var iconCardLeftArrow = "Footer-module--icon-card-left-arrow--j-yKM";
export var iconCardRightArrow = "Footer-module--icon-card-right-arrow--RoItj";
export var iconCase = "Footer-module--icon-case--2gAvg";
export var iconCase2 = "Footer-module--icon-case2--LyQof";
export var iconClose = "Footer-module--icon-close--GKYW3";
export var iconEarth = "Footer-module--icon-earth--uL5eL";
export var iconFacebook = "Footer-module--icon-facebook--1bwp2";
export var iconFavStar = "Footer-module--icon-fav-star--02vQw";
export var iconGlobe = "Footer-module--icon-globe--Te5qQ";
export var iconHeart = "Footer-module--icon-heart--P2tcy";
export var iconHotel = "Footer-module--icon-hotel--OmxzW";
export var iconHouse = "Footer-module--icon-house--UIi5-";
export var iconInstagram = "Footer-module--icon-instagram--88QYH";
export var iconLeftArrow = "Footer-module--icon-left-arrow--00QLB";
export var iconLeftControl = "Footer-module--icon-left-control--MuGzg";
export var iconLinkedin = "Footer-module--icon-linkedin--yJ+O9";
export var iconMessage = "Footer-module--icon-message--bHDcX";
export var iconMug = "Footer-module--icon-mug--dhq9A";
export var iconRightArrow = "Footer-module--icon-right-arrow--J6RjF";
export var iconRightControl = "Footer-module--icon-right-control--BeQD6";
export var iconSearch = "Footer-module--icon-search--P6Jbj";
export var iconShip = "Footer-module--icon-ship--ZcXvQ";
export var iconShirt = "Footer-module--icon-shirt--Uq8Wi";
export var iconSofa = "Footer-module--icon-sofa--L59xS";
export var iconTwitter = "Footer-module--icon-twitter--eIqeF";
export var iconWhatsapp = "Footer-module--icon-whatsapp--eAncW";
export var imageLocation = "Footer-module--image-location--gJoHy";
export var location = "Footer-module--location--ofFpt";
export var menuItem = "Footer-module--menu-item--Ao2ww";
export var navContainer = "Footer-module--nav-container--aVC8o";
export var pages = "Footer-module--pages--yYRRd";
export var phone = "Footer-module--phone--rnW07";
export var social = "Footer-module--social--VXOvH";
export var subItemsMenu = "Footer-module--sub-items-menu--L4lt3";
export var tikiLogo = "Footer-module--tiki-logo--7SLqp";
export var upperFooter = "Footer-module--upper-footer--9FpvO";