import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import cn from 'classnames'
import { useApi } from '~/api'
import { footerItems } from '~/data'
import { year } from '~/utils/getYear'
import * as st from '~/assets/styl/Footer.module.styl'
import logo from '~/assets/img/logo-footer.png'

export default function Footer() {
  const query = useStaticQuery(graphql`
    query {
      allConfigurations {
        nodes {
          facebook
          instagram
          linkedin
          youtube
          phone
          whatsapp
        }
      }
      allAddresses {
        nodes {
          name
          slug
          address
          uf
          city
          neighborhood
          zipCode
          latitude
          longitude
          footer
        }
      }
      allServices {
        nodes {
          name
          slug
          icon
        }
      }
    }
  `)

  const config = useApi(query.allConfigurations.nodes[0], 'configurations')
  const addresses = useApi(query.allAddresses.nodes, 'addresses')
  const services = useApi(query.allServices.nodes, 'services')

  if (services && services.length) {
    const index = footerItems.findIndex((item) => item.name === 'Serviços')

    if (
      index > -1 &&
      footerItems[index] &&
      !footerItems[index].subItems.length
    ) {
      services.map((service) => {
        footerItems[index].subItems.push({
          name: service.name,
          slug: `/servicos/${service.slug}/`,
        })
      })
    }
  }

  return (
    <footer>
      <div className={cn(st.footerContainer, 'footer-wrapper')}>
        <div className={st.upperFooter}>
          <nav>
            <ul className={st.navContainer}>
              {footerItems.map((item, key) => (
                <li className={st.menuItem} key={key}>
                  <Link to={item.slug}>
                    <span>{item.name}</span>
                  </Link>
                  {item.subItems && (
                    <ul className={st.subItemsMenu}>
                      {item.subItems.map((subItem, subKey) => (
                        <li key={subKey}>
                          <Link
                            target={subItem.external && '_blank'}
                            rel={
                              subItem.external && 'noreferrer external nofollow'
                            }
                            to={subItem.slug}
                          >
                            {subItem.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <div className={st.imageLocation}>
            <img src={logo} alt="Logo do Grupo Bauer" />
            <address className={st.location}>
              {addresses.map((address, key) => (
                <span key={key}>
                  <b>{`${address.city} - ${address.uf}`}</b>
                  <br />
                  {address.address}
                  <br />
                  {address.neighborhood ? `${address.neighborhood}` : ''}
                  {address.zip_code ? ` - CEP: ${address.zip_code}` : ''}
                </span>
              ))}
            </address>
            {Boolean(config.phone) && (
              <span className={st.phone}>Telefone: {config.phone}</span>
            )}
          </div>
        </div>
        <div className={st.bottomFooter}>
          <div>
            <small>Bauer Express © {year}. Todos os direitos reservados</small>
            <div className={st.pages}>
              <Link to="/politica-de-privacidade">
                <span>Política de privacidade</span>
              </Link>
            </div>
          </div>
          <div className={st.social}>
            <a
              href={config.facebook}
              target="_blank"
              rel="noreferrer external nofollow"
              className="icon-facebook"
            />
            <a
              href={config.instagram}
              target="_blank"
              rel="noreferrer external nofollow"
              className="icon-instagram"
            />
            <a
              href={config.linkedin}
              target="_blank"
              rel="noreferrer external nofollow"
              className="icon-linkedin"
            />
          </div>
        </div>
        <div className={st.tikiLogo}>
          <a href="https://www.tiki.com.br/" target="_blank" rel="noreferrer">
            Tiki
          </a>
        </div>
      </div>
    </footer>
  )
}
