import { Link } from 'gatsby'
import React from 'react'
import * as st from '~/assets/styl/Breadcrumb.module.styl'

export default function Breadcrumb() {
  return (
    <div className={st.breadcrumbContainer}>
      <ul>
        <li>
          <Link to="/">
            <span className="icon-house"></span>
            <span>Home</span>
          </Link>
        </li>
        <li>
          <Link to="/">
            <span className="icon-left-control"></span>
            <span>Voltar</span>
          </Link>
        </li>
      </ul>
    </div>
  )
}
