import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import api from '~/api'
import * as st from '~/assets/styl/SearchResults.module.styl'

interface Props {
  q?: string
}

export default function SearchResults({ q }: Props) {
  const [searchResult, setSearchResult] = useState({})
  const [haveResult, setHaveResult] = useState(false)
  const [countResults, setCountResults] = useState(0)

  useEffect(() => {
    if (q) {
      api('get', `search?search=${q}`)
        .then((data) => {
          const { result } = data
          if (result) {
            const count =
              result.packages.length +
              result.shoppings.length +
              result.services.length
            setSearchResult(result)
            setHaveResult(count > 0)
            setCountResults(count)
          }
        })
        .catch((err) => console.error(err))
    }
  }, [q])

  return (
    <div className={st.searchContainer}>
      <div className={st.searchWrapper}>
        <aside>
          <section>
            <h2>Resultado da Busca</h2>
            {haveResult ? (
              <p>
                Sua busca por <b>&quot;{q}&quot;</b> encontrou
                <b>
                  {countResults > 1
                    ? ` ${countResults} resultados.`
                    : ` ${countResults} resultado.`}
                </b>
              </p>
            ) : (
              <p>
                Sua busca por
                <b>&quot;{q}&quot;</b>
                não encontrou nenhum resultado
              </p>
            )}
          </section>
        </aside>
        {haveResult && (
          <div className={st.searchResults}>
            {Boolean(searchResult.packages.length) && (
              <section>
                <h2>Pacotes Turísticos</h2>
                <div>
                  <ul>
                    {searchResult.packages.map((item, key) => (
                      <li key={key}>
                        <Link
                          to={'/pacotes/' + item.slug + '/'}
                          title={item.title}
                        >
                          <span className="icon-right-control"></span>
                          <p>{item.name}</p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </section>
            )}
            {Boolean(searchResult.shoppings.length) && (
              <section>
                <h2>Viagem de Compras</h2>
                <div>
                  <ul>
                    {searchResult.shoppings.map((item, key) => (
                      <li key={key}>
                        <Link
                          to={'/viagens/' + item.slug + '/'}
                          title={item.title}
                        >
                          <span className="icon-right-control"></span>
                          <p>{item.name}</p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </section>
            )}
            {Boolean(searchResult.services.length) && (
              <section>
                <h2>Serviços</h2>
                <div>
                  <ul>
                    {searchResult.services.map((item, key) => (
                      <li key={key}>
                        <Link
                          to={'/servicos/' + item.slug + '/'}
                          title={item.title}
                        >
                          <span className="icon-right-control"></span>
                          <p>{item.name}</p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </section>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
